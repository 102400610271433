<template>
  <div class="vipPopWarp vip-pop-box">
    <swiper ref="swiper" :options="swiperOption" v-if="advList.length > 1">
      <swiper-slide class="vipSwiperSlide" v-for="(item,index) in advList" :key="index" >
        <div class="vipCard">
          <img :src="place" class="vipCardImage" :key="index"/>
          <div class="openBtn" data-class="openBtn" :data-item="JSON.stringify(item)" @click="jumpVip(item)">立即开通</div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="vipCard" v-else>
      <ImgDecypt :src="advList[0].popBackgroundImage" class="vipCardImg" />
      <div class="openBtn" data-class="openBtn" @click="jumpVip(advList[0])">立即开通</div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import {swiper, swiperSlide} from "vue-awesome-swiper";
import {imgDecyptApi} from "@/api/app";
import {mapGetters} from "vuex";
import ImgDecypt from "@/components/ImgDecypt"

export default {
  components: {
    swiper,
    swiperSlide,
    ImgDecypt,
  },
  props: {
    advList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    hasPagination: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    }
  },
  computed: {
    ...mapGetters({
      imgApi: "imgApi",
    }),
    swiper() {
      return this.$refs.swiper.swiper;
    }
  },
  // activated() {
    // this.swiper.autoplay.stop();
    // setTimeout(() => {
    //   this.swiper.slideTo(this.swiper.activeIndex + 1,0)
    //   this.swiper.autoplay.start()
    // }, 1000);
  // },
  data() {
    return {
      vipImage: '',
      place: require("@/assets/png/plac_h.png"),
      // 轮播图配置
      swiperOption: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "my-bullet-active",
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        on: {
          click: (event) => {
            let dataset = event.target.dataset;
            // console.log(dataset.class)
            if (dataset.class == "openBtn") {
              // console.log(event.target.dataset.item)
              let item = JSON.parse(event.target.dataset.item);
              this.jumpVip(item);
            }
            // let item = JSON.parse(e.target.dataset.item);
            // console.log(item)
            // console.log(e.target.dataset.item)
            // jumpAdv(item);
          }
        }
      },
      swiperOption2: {
        loop: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "my-bullet-active",
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
      },
    };
  },
  mounted() {
    let imgApi = this.imgApi;
    if (imgApi.slice(-1) != "/") {
      imgApi = imgApi + "/";
    }
    let dom = document.querySelectorAll(".vip-pop-box .vipSwiperSlide");
    // console.log(dom[0].getAttribute("data-swiper-slide-index"))
    let domlen = dom.length;
    for (let i = 0; i < domlen; i++) {
      let imgDom = dom[i].querySelector("img");
      imgDecyptApi(
          imgApi +
          (this.advList.length > 1 ? this.advList[dom[i].getAttribute("data-swiper-slide-index")]
              .popBackgroundImage : this.advList[0].popBackgroundImage)
      ).then((url) => {
        if (imgDom) {
          imgDom.src = url;
        }
      });
    }
  },
  methods: {
    jumpVip(item) {
      let parameter = {t: 'vip', cid: item.id};
      this.$router.push({path: '/memberCentre', query: parameter})
      // console.log(123)
      // console.log(item)
    }
  }
};
</script>
<style lang="scss" scoped>
.vipCardImage {
  width: 325px;
  height: 395px;
  text-align: center;
  background: #242424;

}

.vipPopWarp {
  /deep/ .swiper-pagination {
    bottom: 4px;
    left: auto;
    width: 100%;
    right: 20px;
    text-align: right;
    margin-right: 20px;
    z-index: 999;
  }

  /deep/ .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 2px 4px;
    background: linear-gradient($pumpkinOrange, $brownishOrange);
    opacity: 0.5;
  }

  /deep/ .my-bullet-active {
    background: linear-gradient($pumpkinOrange, $brownishOrange);
    opacity: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .vipSwiperSlide {
    background: #242424;
    text-align: center;
    
  }
  .vipCard {
    position: relative;
    img {
      width: 325px;
      height: 395px;
      border-radius: 4px;
      object-fit: fill;
    }
    .vipCardImg {
      width: 325px;
      height: 395px;
      border-radius: 4px;
      /deep/ .vanImage {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;
      }
    }
    .openBtn {
      position: absolute;
      bottom: 33px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
      width: 272px;
      height: 42px;
      line-height: 42px;
      font-size: 16px;
      border-radius: 21px;
      background: #f2bf71;
      color: #4d3208;
    }
  }
}
</style>
